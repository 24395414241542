import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import villa1Image from "../assets/images/the-villa/1/the-villa1.webp";
import villa2Image from "../assets/images/the-villa/1/the-villa2.webp";
import villa3Image from "../assets/images/the-villa/1/the-villa3.webp";
import villa4Image from "../assets/images/the-villa/1/the-villa4.webp";
import villa5Image from "../assets/images/the-villa/1/the-villa5.webp";
import villa6Image from "../assets/images/the-villa/1/the-villa6.webp";
import villa7Image from "../assets/images/the-villa/1/the-villa7.webp";
import villa8Image from "../assets/images/the-villa/1/the-villa8.webp";
import villa9Image from "../assets/images/the-villa/1/the-villa9.webp";
import villa10Image from "../assets/images/the-villa/1/the-villa10.webp";
import villa11Image from "../assets/images/the-villa/1/the-villa11.webp";
import TheVillaModalCarousel from "./TheVillaModalCarousel";
import lega8957 from "../assets/images/new-villa/LEGA8957.jpg";
import lega8982 from "../assets/images/new-villa/LEGA8982.jpg";
import lega8986 from "../assets/images/new-villa/LEGA8986.jpg";
import lega9002 from "../assets/images/new-villa/LEGA9002.jpg";
import lega9010 from "../assets/images/new-villa/LEGA9010.jpg";
import lega9029 from "../assets/images/new-villa/LEGA9029.jpg";
import lega9030 from "../assets/images/new-villa/LEGA9030.jpg";
import lega9071 from "../assets/images/new-villa/LEGA9071.jpg";
import lega9096 from "../assets/images/new-villa/LEGA9096.jpg";
import lega9097 from "../assets/images/new-villa/LEGA9097.jpg";
import lega9106_1 from "../assets/images/new-villa/LEGA9106_1.jpg";
import lega9192 from "../assets/images/new-villa/LEGA9192.jpg";
import lega9200 from "../assets/images/new-villa/LEGA9200.jpg";
import lega9445 from "../assets/images/new-villa/LEGA9445.jpg";
import rox4718 from "../assets/images/new-villa/ROX_4718.jpg";
import rox4738 from "../assets/images/new-villa/ROX_4738.jpg";
import rox4745 from "../assets/images/new-villa/ROX_4745.jpg";
import rox4756_1 from "../assets/images/new-villa/ROX_4756_1.jpg";
import rox4763 from "../assets/images/new-villa/ROX_4763.jpg";
import rox4770 from "../assets/images/new-villa/ROX_4770.jpg";
import rox4782 from "../assets/images/new-villa/ROX_4782.jpg";
import rox4806 from "../assets/images/new-villa/ROX_4806.jpg";
import rox4815 from "../assets/images/new-villa/ROX_4815.jpg";
import rox4892 from "../assets/images/new-villa/ROX_4892.jpg";
import rox4922 from "../assets/images/new-villa/ROX_4922.jpg";
import rox4996 from "../assets/images/new-villa/ROX_4996.jpg";

export default function TheVillaCarousel1({ openModal, closeModal }) {
  const carouselData = [
    { id: 1, image: lega8957, name: "Bedroom" },
    { id: 2, image: lega8982, name: "Living place" },
    { id: 3, image: lega8986, name: "Outside View" },
    { id: 4, image: lega9002, name: "Outside View" },
    { id: 5, image: lega9010, name: "Outdoor Seating Area" },
    { id: 6, image: lega9029, name: "Indoor Seating Area" },
    { id: 7, image: lega9030, name: "LEGA9030" },
    { id: 8, image: lega9071, name: "Bedroom" },
    { id: 9, image: lega9096, name: "Outdoor Seating Area" },
    { id: 10, image: lega9097, name: "Outdoor Seating Area" },
    { id: 11, image: lega9106_1, name: "Outdoor Seating Area" },
    { id: 12, image: lega9192, name: "Outside View" },
    { id: 13, image: lega9200, name: "Outside View" },
    { id: 14, image: lega9445, name: "Night View" },
    { id: 15, image: rox4718, name: "Outside View" },
    { id: 16, image: rox4738, name: "Outdoor Seating Area" },
    { id: 17, image: rox4745, name: "Outdoor Seating Area" },
    { id: 18, image: rox4756_1, name: "Garden View" },
    { id: 19, image: rox4763, name: "Entrance View" },
    { id: 20, image: rox4770, name: "City View" },
    { id: 21, image: rox4782, name: "Indoor Seating Area" },
    { id: 22, image: rox4806, name: "Leaving Place" },
    { id: 23, image: rox4815, name: "Indoor Chatting Area" },
    { id: 24, image: rox4892, name: "Bedroom" },
    { id: 25, image: rox4922, name: "Indoor Seating Place" },
    { id: 26, image: rox4996, name: "City View" },
    { id: 31, image: villa1Image, name: "DSC04147-HDR" },
    { id: 32, image: villa2Image, name: "Entrance | High Ground Villa" },
    { id: 33, image: villa3Image, name: "Garden | High Ground Villa" },
    { id: 34, image: villa4Image, name: "Garden | High Ground Villa" },
    { id: 35, image: villa5Image, name: "Entrance | High Ground Villa" },
    { id: 36, image: villa6Image, name: "Entrance Sign | High Ground Villa" },
    { id: 37, image: villa7Image, name: "Garden | High Ground Villa" },
    { id: 38, image: villa8Image, name: "Entrance | High Ground Villa" },
    { id: 39, image: villa9Image, name: "Garden | High Ground Villa" },
    { id: 40, image: villa10Image, name: "View | High Ground Villa" },
    { id: 41, image: villa11Image, name: "Night View | High Ground Villa" },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      className="villa-carousel1"
      responsive={responsive}
      keyBoardControl={true}
      draggable={true}
      swipeable={true}
    >
      {carouselData.map((data, index) => (
        <div key={data.id} style={{ position: "relative" }}>
          <img
            src={data.image}
            alt="The Villa"
            onClick={() =>
              openModal(
                <TheVillaModalCarousel
                  openModal={openModal}
                  closeModal={closeModal}
                  data={carouselData}
                  pageNumber={index}
                />
              )
            }
          />
          <p className="carousel-description">{data.name}</p>
        </div>
      ))}
    </Carousel>
  );
}
