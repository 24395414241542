import React from "react";
import "../styles/footer.css";
import facebookImage from "../assets/images/facebook.webp";
import instagramImage from "../assets/images/instagram.webp";
import glassesImage from "../assets/images/glasses.webp";

export default function Footer() {
  const socialIconsData = [
    {
      path: "https://www.facebook.com/HighGroundVilla/?ref=bookmarks",
      icon: facebookImage,
    },
    {
      path: "https://www.instagram.com/highgroundvilla/",
      icon: instagramImage,
    },
    {
      icon: glassesImage,
    },
  ];
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-group">
          <p className="title">Telephone:</p>
          <span className="text-black">+250 788 505777</span>
          <span className="text-black">+250 788 319 737</span>
        </div>
        <div className="footer-group">
          <p className="title">Adresse:</p>
          <span>KK 730 Street, No. 43</span>
          <span>Rebero, Kigali | Rwanda</span>
        </div>
        <div className="footer-group">
          <p className="title">Email:</p>
          <span>
            <a href="mailto:Highgroundvilla@gmail.com">
              Highgroundvilla@gmail.com
            </a>
          </span>
        </div>
      </div>
      <div className="social-icons">
        <div className="icons">
          {socialIconsData.map((icon) => (
            <a
              key={icon.icon}
              href={icon.path}
              target="_blank"
              rel="noreferrer"
            >
              <img src={icon.icon} alt="icon"></img>
            </a>
          ))}
        </div>

        <div className="copyright">
          &copy;{new Date().getFullYear()} By Daniel Dukundane.
        </div>
      </div>
    </div>
  );
}
