import React from "react";
import ContactForm from "../../components/ContactForm";
import ContactInfo from "../../components/ContactInfo";
import Footer from "../../components/Footer";
import LogoContainer from "../../components/LogoContainer";
import Navbar from "../../components/Navbar";
import Title from "../../components/Title";

export default function Contact() {
  return (
    <>
      <LogoContainer />
      <Navbar />
      <div className="yellow-page">
        <Title title="Contact" />
        <ContactInfo />
        <hr
          style={{
            margin: "2rem 0",
          }}
          className="horizontal-dashed-line"
        />
        <ContactForm />
      </div>

      <Footer />
    </>
  );
}
