import React, { useContext } from "react";
import TableHeader from "../../components/admin/TableHeader";
import UsersTable from "../../components/admin/UsersTable";
import AdminLayout from "../../layout/AdminLayout";
import UserContext from "../../data/UserContext";

export default function Users() {
  const [selected, setselected] = React.useState([]);
  const { users } = useContext(UserContext);
  const deleteSelected = () => {
    console.log("Delete", selected);
  };
  // const usersData = [
  //   {
  //     id: 1,
  //     names: "Daniel Dukundane",
  //     email: "dukundanedanny1@gmail.com",
  //     phone: "0788710558",
  //     address: "Afghanistan",
  //     dateOfUsage: "09/08/2021",
  //   },
  //   {
  //     id: 2,
  //     names: "Daniel Dukundane",
  //     email: "dukundanedanny1@gmail.com",
  //     phone: "0788710558",
  //     address: "Afghanistan",
  //     dateOfUsage: "09/08/2021",
  //   },
  //   {
  //     id: 3,
  //     names: "Daniel Dukundane",
  //     email: "dukundanedanny1@gmail.com",
  //     phone: "0788710558",
  //     address: "Afghanistan",
  //     dateOfUsage: "09/08/2021",
  //   },
  //   {
  //     id: 4,
  //     names: "Daniel Dukundane",
  //     email: "dukundanedanny1@gmail.com",
  //     phone: "0788710558",
  //     address: "Afghanistan",
  //     dateOfUsage: "09/08/2021",
  //   },
  // ];
  return (
    <AdminLayout>
      <TableHeader
        title="User Management"
        deleteLabel="Delete User"
        deleteClick={deleteSelected}
      />
      <UsersTable
        users={users.map((user) => {
          return { ...user, id: user._id };
        })}
        changeSelected={(values) => setselected(values)}
      />
    </AdminLayout>
  );
}
