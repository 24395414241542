import React from "react";
import LinearChart from "../../components/admin/LineChart";
import AdminLayout from "../../layout/AdminLayout";
import traffic from "../../utils/traffic.js";

export default function Traffic() {
  return (
    <AdminLayout>
      <div style={{ padding: "20px" }}>
        <h2>Site traffic </h2>
        <div style={{ padding: "20px", marginTop: "50px" }}>
          <LinearChart data={traffic} />
        </div>
      </div>
    </AdminLayout>
  );
}
