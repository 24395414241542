import React from "react";

export default function BookingsTable({ bookings = [], changeSelected }) {
  const [active, setactive] = React.useState(null);
  const [selected, setselected] = React.useState([]);
  const changeActive = (newValue) => {
    setactive(active === newValue ? null : newValue);
  };
  const handleChecked = (e, targetedId) => {
    if (e.target.checked) {
      if (!selected.includes(targetedId))
        setselected([...selected, targetedId]);
    } else {
      setselected(selected.filter((id) => id !== targetedId));
    }
  };

  const selectAll = (select = true) => {
    setselected(select ? bookings.map((booking) => booking.id) : []);
  };

  let allSelected = selected.length > 0 && selected.length === bookings.length;

  React.useEffect(() => {
    changeSelected && changeSelected(selected);
    // eslint-disable-next-line
  }, [selected]);
  return (
    <div className="responsive-table-container">
      <table className="responsive-table">
        <tr onClick={() => changeActive(null)}>
          <th>
            <input
              style={{ marginRight: "0.5rem" }}
              type="checkbox"
              checked={allSelected}
              onChange={(e) => selectAll(e.target.checked)}
            ></input>{" "}
            Select
          </th>
          <th className="font-arial">Name</th>
          <th className="font-arial">Room</th>
          <th className="font-arial">Adults</th>
          <th className="font-arial">Children</th>
          <th className="font-arial">Check In</th>
          <th className="font-arial">Check out</th>
          <th></th>
        </tr>
        {bookings.map((booking) => (
          <>
            <tr className="space" onClick={() => changeActive(null)}></tr>
            <tr className={`${active === booking.id && "active"}`}>
              <td onClick={() => changeActive(null)}>
                <input
                  type="checkbox"
                  checked={selected.includes(booking.id)}
                  onChange={(e) => handleChecked(e, booking.id)}
                ></input>
              </td>
              <td className="font-arial" onClick={() => changeActive(null)}>
                {booking.customer?.fname + " " + booking.customer?.lname}
              </td>
              <td className="font-arial" onClick={() => changeActive(null)}>
                {booking.room?.name || "Shushu"}
              </td>
              <td className="font-arial" onClick={() => changeActive(null)}>
                {booking.adults || 1}
              </td>
              <td className="font-arial" onClick={() => changeActive(null)}>
                {booking.kids || 0}
              </td>
              <td className="font-arial" onClick={() => changeActive(null)}>
                {new Date(booking.start_date).toLocaleDateString()}
              </td>
              <td className="font-arial" onClick={() => changeActive(null)}>
                {new Date(booking.end_date).toLocaleDateString()}
              </td>
              <td style={{ position: "relative" }}>
                <div>
                  <p
                    className="cursor-pointer"
                    onClick={() => changeActive(booking.id)}
                  >
                    ...
                  </p>
                  <div
                    className="table-popup"
                    onClick={() => changeActive(null)}
                  >
                    <p>Delete</p>
                  </div>
                </div>
              </td>
            </tr>
          </>
        ))}
      </table>
    </div>
  );
}
