import React from "react";
import logo from "../assets/images/logo.webp";

export default function LogoContainer() {
  return (
    <div className="logo-container">
      <div className="img-container">
        <img src={logo} alt="High Ground Villa logo" />
      </div>
      <div className="bottom">
        <div className="triangle-topleft" />
        <div className="triangle-topright" />
      </div>
    </div>
  );
}
