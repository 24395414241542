import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

export default function Navbar() {
  const [isShown, setisShown] = React.useState(false);

  const location = useLocation();
  const history = useHistory();
  const toggleNavbar = () => {
    setisShown(!isShown);
  };
  const links = [
    { text: "Home", path: "/" },
    { text: "The Villa", path: "/the-villa" },
    { text: "The rooms", path: "/the-rooms" },
    { text: "Contact", path: "/contact" },
  ];
  return (
    <div className="navbar">
      <div className={`nav-toggler ${isShown ? "no-right" : "right"}`}>
        {isShown ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="28"
            height="28"
            fill="#fff"
            onClick={toggleNavbar}
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        ) : (
          <svg
            onClick={toggleNavbar}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="28"
            height="28"
            fill="#fff"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z" />
          </svg>
        )}
      </div>
      <ul className={`links-list ${!isShown && "md-hidden"}`}>
        {links.map((link) => (
          <li
            className={`link-item ${
              location.pathname === link.path ||
              location.pathname.startsWith(link.path + "/")
                ? "active"
                : ""
            }`}
            key={link.text}
            onClick={() => history.push(link.path)}
          >
            <Link to={link.path}>{link.text}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
