import React from "react";
import familyImage from "../assets/images/family.webp";

export default function DesignerComponent() {
  return (
    <div className="designer">
      <p>
        Your host, Claude, designed High Ground Villa with guest satisfaction in
        mind. There are four kitchens with 12 rooms & 12 bathrooms, colorful
        gardens, & peaceful balconies where you can enjoy a book in solitude or
        join your friends in good conversation. Spacious indoor living areas
        welcome guests with comfortable beds & sofas.
      </p>
      <img src={familyImage} alt="Host, Claude" />
    </div>
  );
}
