import "../../styles/atoms/chat.css";
import noChatOpenImage from "../../assets/svgs/no-chat-open.svg";
import ENDPOINT from "../../helpers/url";
import { useState, useContext } from "react";
import UserContext from "../../data/UserContext";
import toast from "react-hot-toast";

export default function ChatDetails({ chat }) {
  const { user } = useContext(UserContext);

  const [values, setValues] = useState({
    message: "",
  });

  const reply = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.token,
      },
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(
        `${ENDPOINT}/api/v1/contact/${chat._id}/reply`,
        requestOptions
      );
      const data = await response.json();
      if (data) {
        toast.success("Message sent successfully");
        setValues({ message: "" });
      }
    } catch (error) {
      toast.error("Reply not sent. error occurred");
    }
  };
  return (
    <div className="chat-details-box">
      {!chat ? (
        <div>
          <img src={noChatOpenImage} alt="Open chat to continue" />
        </div>
      ) : (
        <div className="parent">
          <div className="bg-light-blue chat-message">
            <div className="py-2">
              <p>Contact</p>
            </div>
            <div className="py-2">
              <p>NAME</p>
              <p>{chat.name}</p>
            </div>
            <div className="py-2">
              <p>EMAIL</p>
              <p>{chat.email}</p>
            </div>
            <div className="py-2">
              <p>SUBJECT</p>
              <p>{chat.subject}</p>
            </div>
            <div className="py-2">
              <p>MESSAGE</p>
              <p>{chat.message}</p>
            </div>
          </div>
          <div className="reply">
            <div>
              <input
                value={values.message}
                placeholder={`Message ${chat.name} via email`}
                onChange={(e) => setValues(e.target.value)}
              />
              <button onClick={reply}>Send</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
