import { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.webp";
import LoadingSpinner from "../../components/shared/loading-spinner";
import "../../styles/register.css";
import ENDPOINT from "../../helpers/url";
import toast from "react-hot-toast";

export default function Register() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [values, setvalues] = useState({});

  const { search } = useLocation();
  const nextPage = new URLSearchParams(search).get("next");

  const handleInputChange = (e) => {
    setvalues({ ...values, [e.target.name]: e.target.value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (values.password !== values.cpassword) {
        toast.error("Passwords do not match");
        return;
      }
      let { cpassword, ...restValues } = values;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(restValues),
      };
      const response = await fetch(`${ENDPOINT}/api/v1/user`, requestOptions);
      const data = await response.json();

      if (data) {
        if (data._id) {
          toast.success("You are successifully registered");

          if (nextPage) history.push(`/login?next=${nextPage}`);
          else history.push("/login");
        } else {
          toast.error(data.message);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Registration Failed. Please try again later");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-page">
      <div className="register-form-logo">
        <Link to="/">
          <div className="register-logo-container cursor-pointer">
            <img src={logo} alt="High Ground Villa logo" />
          </div>
        </Link>
      </div>
      <div className="register-form-container">
        <form onSubmit={handleRegister}>
          <h1>Create account</h1>
          <div className="input-container">
            <input
              required
              type="text"
              name="fname"
              value={values.fname}
              onChange={handleInputChange}
              placeholder="First Name"
            />
          </div>
          <div className="input-container">
            <input
              required
              type="text"
              name="lname"
              value={values.lname}
              onChange={handleInputChange}
              placeholder="Last Name"
            />
          </div>
          <div className="input-container">
            <input
              required
              type="email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              placeholder="Email"
            />
          </div>
          <div className="input-container">
            <input
              required
              type="password"
              name="password"
              value={values.password}
              onChange={handleInputChange}
              placeholder="Password"
            />
          </div>
          <div className="input-container">
            <input
              required
              type="password"
              name="cpassword"
              value={values.cpassword}
              onChange={handleInputChange}
              placeholder="Confirm Password"
            />
          </div>
          <div className="input-container">
            <button type="submit" disabled={loading}>
              {loading ? <LoadingSpinner /> : "Create Account"}
            </button>
          </div>
          <div className="link">
            <p>Already have an account?</p>
            <Link to={`/login${nextPage ? `?next=${nextPage}` : ""}`}>
              Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
