import React from "react";
import logo from "../../assets/images/logo.webp";

export default function AdminNavbar({ changeShown }) {
  return (
    <div className="admin-navbar">
      <div className="left">
        <img
          src={logo}
          alt="High Ground Villa Logo"
          onClick={changeShown}
        ></img>
      </div>
      <div className="right"></div>
    </div>
  );
}
