import Avatar from "../users/Avatar";
import "../../styles/atoms/chat.css";

export default function Chats({ activeChat, chats, handleOpenChat }) {
  const handleChangeChatTo = (chat) => {
    handleOpenChat(chat);
  };
  return (
    <div
      style={{
        borderRight: "2px solid #707070",
        width: "33%",
      }}
    >
      {chats.map((chat) => (
        <div
          className="single-chat"
          key={chat._id}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            padding: 15,
            cursor: "pointer",
            backgroundColor: activeChat === chat?._id ? "#F0F4F7" : "white",
          }}
          onClick={(e) => handleChangeChatTo(chat)}
        >
          <Avatar name={chat.name} />
          <p className="contact-name">{chat.name}</p>
        </div>
      ))}
    </div>
  );
}
