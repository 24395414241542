import { Chart, Line, Point, Tooltip } from "bizcharts";

export default function LinearChart({ data }) {
  return (
    <Chart padding={[10, 20, 50, 50]} autoFit height={400} data={data}>
      <Line
        position="day*visitors"
        shape="smooth"
        tooltip={[
          "day*visitors",
          (y, v) => {
            return {
              title: y,
              name: "visitors",
              value: v,
            };
          },
        ]}
      />
      <Point position="day*visitors" />
      <Tooltip showCrosshairs lock />
    </Chart>
  );
}
