import React from "react";
import { NavLink } from "react-router-dom";

export default function AdminSidebar({ shown }) {
  return (
    <div className={`admin-sidebar ${shown && "shown"}`}>
      <NavLink exact to="/admin" activeClassName="active">
        Site Traffic
      </NavLink>
      <NavLink to="/admin/users" activeClassName="active">
        User Management
      </NavLink>
      <NavLink to="/admin/bookings" activeClassName="active">
        Bookings Management
      </NavLink>
      <NavLink to="/admin/mailbox" activeClassName="active">
        Mailbox
      </NavLink>
    </div>
  );
}
