import { useContext } from "react";
import { useLocation } from "react-router-dom";
import RoomsFilters from "../../components/Filters/RoomsFilters";
import Footer from "../../components/Footer";
import LogoContainer from "../../components/LogoContainer";
import Navbar from "../../components/Navbar";
import RoomsList from "../../components/RoomsList";
import Title from "../../components/Title";
import RoomsContext from "../../data/RoomsContext";
import "../../styles/the-rooms.css";

export default function Rooms() {
  const { rooms } = useContext(RoomsContext);
  const location = useLocation();
  let searchValues = {};

  let search = location.search.slice(1);
  search = search.split("&");
  search.forEach((keyValue) => {
    let key = keyValue.split("=")[0];
    let value = keyValue.split("=")[1];
    searchValues = { ...searchValues, [key]: value };
  });

  let { adults, kids, startDate, endDate } = searchValues;
  adults = parseInt(adults) || 0;
  kids = parseInt(kids) || 0;
  if (startDate === "undefined" || startDate === "null") startDate = null;
  if (endDate === "undefined" || endDate === "null") endDate = null;

  const isSearch = adults || kids || startDate || endDate;

  let searchAccomodates = adults + kids || 0;
  let displayRooms = rooms.filter(
    (room) => room.maxAccomodate >= searchAccomodates
  );
  return (
    <>
      <LogoContainer />
      <Navbar />
      <div className="yellow-page">
        <Title
          title="The rooms"
          paragraph="Each rooms offer you hot showers, fast wifi, & clean rooms at High Ground Villa. Claude and his team work hard to ensure that your visit will be safe in a Christian environment that honors Africa."
        />
        <p className="subtitle font-poppins">Our Rooms</p>
        <hr className="horizontal-line" />
        <RoomsFilters
          isSearch={isSearch}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          defaultAdults={adults}
          defaultKids={kids}
        />
        <hr className="horizontal-line" />
        <RoomsList rooms={displayRooms} />
      </div>

      <Footer />
    </>
  );
}
