import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DateRangeFilters({
  labelInline = false,
  handleInputChange,
}) {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  React.useEffect(() => {
    if (endDate && startDate > endDate) {
      setEndDate(null);
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    handleInputChange("startDate", startDate?.toISOString());
    // eslint-disable-next-line
  }, [startDate]);

  React.useEffect(() => {
    handleInputChange("endDate", endDate?.toISOString());
    // eslint-disable-next-line
  }, [endDate]);

  return (
    <>
      <ReactDatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        minDate={new Date()}
        dateFormat="dd/MM/yyyy"
        customInput={
          <ExampleCustomInput label="Arrivée" labelInline={labelInline} />
        }
      />
      <ReactDatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate || new Date()}
        dateFormat="dd/MM/yyyy"
        customInput={
          <ExampleCustomInput label="Départ" labelInline={labelInline} />
        }
      />
    </>
  );
}

const ExampleCustomInput = forwardRef(
  ({ value, onClick, label, labelInline }, ref) => (
    <div className="custom-date-input" onClick={onClick} ref={ref}>
      {!labelInline && <label className="filter-label">{label}</label>}
      <div className="value">
        <p>{value || (labelInline ? label : "DD MM YYYY")}</p>
        <svg
          width="20"
          height="20"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36 3.5C36.8284 3.5 37.5 4.17157 37.5 5V7H44C45.6569 7 47 8.34315 47 10V44C47 45.6569 45.6569 47 44 47H6C4.34315 47 3 45.6569 3 44V10C3 8.34315 4.34315 7 6 7H12.5V5C12.5 4.17157 13.1716 3.5 14 3.5C14.8284 3.5 15.5 4.17157 15.5 5V7H34.5V5C34.5 4.17157 35.1716 3.5 36 3.5ZM12.5 10V14C12.5 14.8284 13.1716 15.5 14 15.5C14.8284 15.5 15.5 14.8284 15.5 14V10H34.5V14C34.5 14.8284 35.1716 15.5 36 15.5C36.8284 15.5 37.5 14.8284 37.5 14V10H44V18.5H6V10H12.5ZM6 21.5L6 44H44V21.5H6Z"
            fill="#000000"
          ></path>
        </svg>
      </div>
    </div>
  )
);
