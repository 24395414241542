import React from "react";
import Footer from "../components/Footer";
import LogoContainer from "../components/LogoContainer";
import Navbar from "../components/Navbar";

export default function Notfound() {
  return (
    <>
      <LogoContainer />
      <Navbar />
      <div style={{ height: "15rem" }} />

      <Footer />
    </>
  );
}
