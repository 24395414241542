import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { RoomsContextProvider } from "./data/RoomsContext";
import { UserContextProvider } from "./data/UserContext";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <UserContextProvider>
        <RoomsContextProvider>
          <App />
        </RoomsContextProvider>
      </UserContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
