import React from "react";
import { Link, useHistory } from "react-router-dom";

export default function RoomListItem({
  photos,
  name,
  description,
  queenBeds = 0,
  twinBeds = 0,
  price,
  _id: id,
  amenities = [],
}) {
  let history = useHistory();
  const handleImageClick = (e) => {
    history.push(`/the-rooms/${id}`);
  };
  return (
    <>
      <div className="rooms-list-item">
        <div>
          <img
            onClick={handleImageClick}
            src={photos[0]}
            alt={name}
            className="cursor-pointer"
          />
          <Link to={`/the-rooms/${id}`}>More Info</Link>
          <div className="room-info-md">
            <h4 className="font-medium">{name}</h4>
            <h6 className="font-light">
              <p>From</p>
              <span className="font-light">{price}$</span>
            </h6>
          </div>
        </div>
        <div>
          <h5 className="font-medium cursor-pointer" onClick={handleImageClick}>
            {name}
          </h5>
          <p>{description}</p>
          <li>
            {queenBeds} Queen(s), {twinBeds} Twin(s)
          </li>
          <hr className="horizontal-line"></hr>
          <div className="amenities">
            {amenities.map((amenity) => (
              <img
                src={`/icons/${amenity}.svg`}
                alt={amenity}
                title={amenity}
              />
            ))}
          </div>
        </div>
        <div>
          <p>From</p>
          <h6 className="font-light">{price}$</h6>
          <Link to={`/the-rooms/${id}`}>More Info</Link>
        </div>
      </div>
      <hr className="horizontal-line"></hr>
    </>
  );
}
