import React from "react";

export default function FilterInput({
  label,
  labelInline = false,
  name,
  value,
  inputHandler,
}) {
  return (
    <>
      <div className="filterInput">
        {!labelInline && <p className="filter-label">{label}</p>}
        <div className="filter-container">
          <p className="font-16">
            {value} {labelInline ? label : ""}
          </p>
          <div className="controls">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              onClick={() => inputHandler(name, value + 1)}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 8l6 6H6z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              className={`${value < 1 && "disabled"}`}
              onClick={() => {
                if (value > 0) inputHandler(name, value - 1);
              }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 16l-6-6h12z" />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
