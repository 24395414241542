import React from "react";

export default function Title({ title = "", paragraph = "" }) {
  return (
    <>
      <div className="title-component">
        <p className="font-niconne">{title}</p>
        <hr />
      </div>
      {paragraph && <p className="title-component-text">{paragraph}</p>}
    </>
  );
}
