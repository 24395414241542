import toast from "react-hot-toast";
import ENDPOINT from "../helpers/url";

export async function loadUsers(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "x-auth-token": token,
    },
  };
  try {
    const response = await fetch(`${ENDPOINT}/api/V1/user`, requestOptions);
    const data = await response.json();
    return data || [];
  } catch (error) {
    toast.error("Error when loading users");
    return [];
  }
}

export async function loadBookings(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "x-auth-token": token,
    },
  };
  try {
    const response = await fetch(`${ENDPOINT}/api/V1/booking`, requestOptions);
    const data = await response.json();
    return data || [];
  } catch (error) {
    toast.error("Error occured when loading bookings");
    return [];
  }
}

export async function loadChats(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "x-auth-token": token,
    },
  };
  try {
    const response = await fetch(`${ENDPOINT}/api/v1/contact`, requestOptions);
    const data = await response.json();
    return data || [];
  } catch (error) {
    toast.error("Error occured when loading contacts");
    return [];
  }
}
