import React from "react";

import AdminNavbar from "../components/admin/AdminNavbar";
import AdminSidebar from "../components/admin/AdminSidebar";
import "../styles/admin.css";

export default function AdminLayout({ children }) {
  const [shown, setshown] = React.useState(false);
  const changeShown = () => {
    setshown(!shown);
  };
  return (
    <div className="admin-page">
      <AdminNavbar changeShown={changeShown} />
      <AdminSidebar shown={shown} />

      <div className="admin-content">{children}</div>
    </div>
  );
}
