import React from "react";
import DesignerComponent from "../../components/DesignerComponent";
import Footer from "../../components/Footer";
import TheVillaCarousel1 from "../../components/TheVillaCarousel1";
import TheVillaCarousel2 from "../../components/TheVillaCarousel2";
import Title from "../../components/Title";
import "../../styles/villa.css";
import LogoContainer from "../../components/LogoContainer";
import Navbar from "../../components/Navbar";

export default function Villa({ openModal, closeModal }) {
  return (
    <>
      <LogoContainer />
      <Navbar />
      <div className="yellow-page">
        <Title
          title="High Ground Villa"
          paragraph="The villa sits atop Rebero Mountain with panoramic views of Kigali and the endless hills of Rwanda. Its unique floorplan offers guests a variety of lodging options, from single room guesthouse stays to apartment-style living for researchers & business travelers. For larger groups, the entire villa can be easily booked to accommodate all your needs for short or long-term visits."
        />
        <TheVillaCarousel1 openModal={openModal} closeModal={closeModal} />
        <hr className="horizontal-dashed-line" style={{ margin: "1rem 0" }} />
        <TheVillaCarousel2 openModal={openModal} closeModal={closeModal} />
        <hr className="horizontal-dashed-line" style={{ margin: "1rem 0" }} />
        <DesignerComponent />
      </div>

      <Footer />
    </>
  );
}
