import React, { useContext } from "react";
import TableHeader from "../../components/admin/TableHeader";
import BookingsTable from "../../components/admin/BookingsTable";
import AdminLayout from "../../layout/AdminLayout";
import UserContext from "../../data/UserContext";

export default function Bookings() {
  const [selected, setselected] = React.useState([]);
  const { bookings } = useContext(UserContext);
  const deleteSelected = () => {
    console.log("Delete", selected);
  };
  return (
    <AdminLayout>
      <TableHeader
        title="Bookings"
        deleteLabel="Delete Booking"
        deleteClick={deleteSelected}
      />
      <BookingsTable
        bookings={bookings.map((booking) => {
          return { ...booking, id: booking._id };
        })}
        changeSelected={(values) => setselected(values)}
      />
    </AdminLayout>
  );
}
