import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router-dom";
import RoomDetailsFilters from "./Filters/RoomDetailsFilters";

export default function RoomDetails({ room = {} }) {
  const history = useHistory();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  let carousel;

  return (
    <>
      <div className="room-details large">
        <div className="left">
          <div className="room-header">
            <svg
              onClick={() => history.push("/the-rooms")}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="32"
              height="32"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z" />
            </svg>
            <h4>{room.name}</h4>
          </div>
          <Carousel
            className="room-details-carousel"
            responsive={responsive}
            keyBoardControl={true}
            draggable={true}
            swipeable={true}
            ref={(ref) => (carousel = ref)}
          >
            {room.photos.map((data, index) => (
              <div key={index} style={{ position: "relative" }}>
                <img src={data} alt={room.name} />
              </div>
            ))}
          </Carousel>
          <div className="room-details-carousel-footer">
            {room.photos.map((data, index) => (
              <div key={index} style={{ position: "relative" }}>
                <img
                  src={data}
                  alt={room.name}
                  onClick={() => carousel && carousel.goToSlide(index)}
                />
              </div>
            ))}
          </div>

          <div className="room-details-row">
            <h5>Properties:</h5>
            <p>Accomodates: {room.maxAccomodate}</p>
            <p>
              Beds: {room.queenBeds} Queen(s), {room.twinBeds} Twin(s)
            </p>
          </div>
          <hr className="horizontal-line"></hr>

          <div className="room-details-row">
            <h5>More Info:</h5>
            <p>{room.description}</p>
          </div>
          <hr className="horizontal-line"></hr>

          <div className="room-details-row">
            <h5>Amenities:</h5>
            <div className="amenities">
              {room.amenities.map((amenity) => (
                <p key={amenity}>
                  <img
                    height="24"
                    width="24"
                    className="inline"
                    src={`/icons/${amenity}.svg`}
                    alt={amenity}
                    title={amenity}
                  />
                  <span
                    style={{ padding: "0 10px", textTransform: "capitalize" }}
                  >
                    {" "}
                    {amenity}
                  </span>
                </p>
              ))}
            </div>
          </div>
          <hr className="horizontal-line" />

          <div className="room-details-row">
            <h5>Check In and Out:</h5>
            <p>Check-In: 03:00 PM</p>
            <p>Check-Out: 11:00 AM</p>
          </div>
          <hr className="horizontal-line" />

          <div className="room-details-row">
            <h5>Terms:</h5>
            <p>
              <a href="https://hotels.wixapps.net/index.html?pageId=cdq0&compId=hziiyyam&viewerCompId=hziiyyam&siteRevision=68&viewMode=site&deviceType=desktop&locale=fr&regionalLanguage=fr&width=980&height=1430&instance=Ie4_yJLUCV6W8W_d8xL0-WDRcDr4LOIKAI7fzJGiv6w.eyJpbnN0YW5jZUlkIjoiMTY5OGJlY2MtZmYzYS00NTM4LTlkZmItMmU3YzU5YmYxOTQ3IiwiYXBwRGVmSWQiOiIxMzVhYWQ4Ni05MTI1LTYwNzQtNzM0Ni0yOWRjNmEzYzliY2YiLCJtZXRhU2l0ZUlkIjoiYzk5YzM1YzQtMWJjOC00NmVlLTlmMGEtYmVkMjA2ZThlMGQ1Iiwic2lnbkRhdGUiOiIyMDIxLTA5LTA1VDA3OjAzOjE3Ljk4NVoiLCJ2ZW5kb3JQcm9kdWN0SWQiOiJDb21ibyIsImRlbW9Nb2RlIjpmYWxzZSwib3JpZ2luSW5zdGFuY2VJZCI6IjEzYzIxYmZiLTlmNzgtYWZiNy1lZWZjLTYxMmE2YjViM2UwMyIsImFpZCI6Ijk2ZDhiMzBmLTMzNmItNGRmYy04MzkzLWE3YmJhNDZiODRjNiIsImJpVG9rZW4iOiJkZjA0OGIwOC1lNGYyLTAzZDYtMDJmMS05MGFlNWY1N2Y5OTIiLCJzaXRlT3duZXJJZCI6ImY0MDUwNWFlLTY4ZGEtNGExMC04YzNhLWE4ZDJkMjRmY2FjZCJ9&commonConfig=%7B%22brand%22%3A%22wix%22%2C%22bsi%22%3A%22908aa725-9c03-4ed4-bec3-8042d62f68c8%7C1%22%2C%22BSI%22%3A%22908aa725-9c03-4ed4-bec3-8042d62f68c8%7C1%22%7D&target=_top&section-url=https%3A%2F%2Fwww.highgroundvilla.com%2Fthe-rooms%2F&vsi=18418438-f9b9-457d-8fac-b44764086912&userLanguage=en#/terms/e752a09d-0d15-4bda-bdb2-e7bb631efee7">
                Read Our Policies
              </a>
            </p>
          </div>
          <hr className="horizontal-line" />
        </div>
        <div className="right">
          <RoomDetailsFilters roomId={room._id} price={room.price} />
        </div>
      </div>
      <div className="room-details small">
        <div className="room-header">
          <svg
            onClick={() => history.push("/the-rooms")}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="32"
            height="32"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z" />
          </svg>
          <h4>Our Rooms</h4>
        </div>
        <div className="room-list-item">
          <img src={room.image} alt={room.name} />
          <div className="room-info-md">
            <h4>{room.name}</h4>
            <h6>
              <p>From</p>
              {room.price}
            </h6>
          </div>
        </div>
        <button className="button">Check Dates</button>
        <hr className="horizontal-line" style={{ marginTop: "1rem" }} />
        <div className="room-details-row no-margin">
          <div className="amenities large" style={{ display: "flex" }}>
            {room.amenities.map((amenity) => (
              <p key={amenity}>
                <img
                  height="28"
                  width="28"
                  className="inline"
                  style={{ marginRight: "10px" }}
                  src={`/icons/${amenity}.svg`}
                  alt={amenity}
                  title={amenity}
                />
              </p>
            ))}
          </div>
        </div>
        <hr className="horizontal-line" style={{ marginTop: "1rem" }}></hr>
        <p style={{ marginTop: "1rem" }}>{room.beds?.twins} Twin(s)</p>
        <p style={{ marginTop: "1rem" }}>{room.description}</p>
      </div>
    </>
  );
}
