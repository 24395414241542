import { useContext } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import LogoContainer from "../../components/LogoContainer";
import Navbar from "../../components/Navbar";
import RoomDetails from "../../components/RoomDetails";
import Title from "../../components/Title";
import RoomsContext from "../../data/RoomsContext";
import "../../styles/room-details.css";

export default function RoomDetailsPage() {
  const { getRoomDetails } = useContext(RoomsContext);
  const { id } = useParams();

  //logic to get room goes here
  const room = getRoomDetails(id);

  return (
    <>
      <LogoContainer />
      <Navbar />
      <div className="yellow-page padding-sm">
        <Title
          title="The Rooms"
          paragraph="Each rooms offer you hot showers, fast wifi, & clean rooms at High Ground Villa. Claude and his team work hard to ensure that your visit will be safe in a Christian environment that honors Africa."
        />
        {room && <RoomDetails room={room} />}
      </div>

      <Footer />
    </>
  );
}
