import React from "react";

export default function ContactInfo() {
  return (
    <div className="contact-info">
      <div className="contact-group">
        <span style={{ fontSize: "14px" }}>
          High Ground Villa is centrally located to all points in Kigali City
          with no more than 15 minutes of travel time to most destinations.
        </span>
      </div>
      <div className="contact-group">
        <h5>High Ground Villa</h5>
        <span>KK 730 Street, No. 43</span>
        <span>Rebero, Kigali | Rwanda</span>
      </div>
      <div className="contact-group">
        <h5 className="center">Telephone :</h5>
        <span>+250 788 505777</span>
        <span>+250 788 319 721</span>
      </div>
    </div>
  );
}
