import React from "react";
import RoomListItem from "./RoomListItem";

export default function RoomsList({ rooms = [] }) {
  return (
    <div className="rooms-list">
      {rooms.map((room) => (
        <RoomListItem key={room._id} {...room} />
      ))}
    </div>
  );
}
