import { useContext, useState } from "react";
import ChatDetails from "../../components/admin/ChatDetails";
import Chats from "../../components/admin/Chats";
import UserContext from "../../data/UserContext";
import AdminLayout from "../../layout/AdminLayout";
import "../../styles/atoms/chat.css";

export default function Mailbox() {
  const [openChat, setOpenChat] = useState(null);

  const { chats } = useContext(UserContext);
  const handleOpenChat = (chat) => {
    setOpenChat(chat);
  };

  return (
    <AdminLayout>
      <div className="flex gap-2">
        <Chats
          activeChat={openChat?._id || 0}
          chats={chats}
          handleOpenChat={handleOpenChat}
        />
        <ChatDetails chat={openChat} />
      </div>
    </AdminLayout>
  );
}
