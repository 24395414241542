import { createContext, useEffect, useState } from "react";
import { loadRooms } from "./roomsFunctions";

const RoomsContext = createContext();

export function RoomsContextProvider({ children }) {
  const [rooms, setrooms] = useState([]);

  useEffect(() => {
    async function laodRoomsData() {
      setrooms(await loadRooms());
    }
    laodRoomsData();
  }, []);

  const getRoomDetails = (id) => {
    return rooms.find((room) => room._id === id);
  };
  return (
    <RoomsContext.Provider value={{ rooms, getRoomDetails }}>
      {children}
    </RoomsContext.Provider>
  );
}

export default RoomsContext;
