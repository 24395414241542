const traffic = [
  {
    day: "11",
    visitors: 0,
  },
  {
    day: "12",
    visitors: 6,
  },
  {
    day: "13",
    visitors: 12,
  },
  {
    day: "14",
    visitors: 19,
  },
  {
    day: "15",
    visitors: 46,
  },
  {
    day: "16",
    visitors: 61,
  },
  {
    day: "17",
    visitors: 32,
  },
  {
    day: "18",
    visitors: 81,
  },
  {
    day: "19",
    visitors: 46,
  },
  {
    day: "20",
    visitors: 61,
  },
  {
    day: "21",
    visitors: 32,
  },
  {
    day: "22",
    visitors: 81,
  },
];

export default traffic;
