import "../../styles/atoms/avatar.css";
import { useMemo } from "react";

export default function Avatar({ name }) {
  let colors = [
    "#781811",
    "#117855",
    "#783a11",
    "#782911",
    "#115278",
    "#116778",
    "#11786f",
    "#117852",
    "#112078",
    "#113778",
    "#4d1178",
    "#271178",
    "#e87b0e",
    "#f7be11",
  ];

  let colorIndex = useMemo(
    () => parseInt(Math.floor(Math.random() * 100) % 14),
    []
  );

  return (
    <span className="avatar" style={{ backgroundColor: colors[colorIndex] }}>
      {name[0]}
    </span>
  );
}
