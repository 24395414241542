import React from "react";

export default function UsersTable({ users = [], changeSelected }) {
  const [active, setactive] = React.useState(null);
  const [selected, setselected] = React.useState([]);
  const changeActive = (newValue) => {
    setactive(active === newValue ? null : newValue);
  };

  const handleChecked = (e, targetedId) => {
    if (e.target.checked) {
      if (!selected.includes(targetedId))
        setselected([...selected, targetedId]);
    } else {
      setselected(selected.filter((id) => id !== targetedId));
    }
  };

  const selectAll = (select = true) => {
    setselected(select ? users.map((user) => user.id) : []);
  };

  let allSelected = selected.length > 0 && selected.length === users.length;

  React.useEffect(() => {
    changeSelected && changeSelected(selected);
    // eslint-disable-next-line
  }, [selected]);
  return (
    <div className="responsive-table-container">
      <table className="responsive-table">
        <thead>
          <tr onClick={() => changeActive(null)}>
            <th>
              <input
                style={{ marginRight: "0.5rem" }}
                type="checkbox"
                checked={allSelected}
                onChange={(e) => selectAll(e.target.checked)}
              ></input>
              Select
            </th>
            <th className="font-arial">Name</th>
            <th className="font-arial">Email</th>
            <th className="font-arial">Phone</th>
            <th className="font-arial">Address</th>
            <th className="font-arial">Date of Usage</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <>
              <tr className="space" onClick={() => changeActive(null)}></tr>
              <tr className={`${active === user.id && "active"}`}>
                <td onClick={() => changeActive(null)}>
                  <input
                    type="checkbox"
                    checked={selected.includes(user.id)}
                    onChange={(e) => handleChecked(e, user.id)}
                  ></input>
                </td>
                <td className="font-arial" onClick={() => changeActive(null)}>
                  {`${user.fname || "John"} ${user.lname || "Doe"}`}
                </td>
                <td className="font-arial" onClick={() => changeActive(null)}>
                  {user.email}
                </td>
                <td className="font-arial" onClick={() => changeActive(null)}>
                  {user.phone || "N/A"}
                </td>
                <td className="font-arial" onClick={() => changeActive(null)}>
                  {user.address || "N/A"}
                </td>
                <td className="font-arial" onClick={() => changeActive(null)}>
                  {user.dateOfUsage ||
                    new Date(user.createdAt).toLocaleDateString()}
                </td>
                <td style={{ position: "relative" }}>
                  <div>
                    <p
                      className="cursor-pointer"
                      onClick={() => changeActive(user.id)}
                    >
                      ...
                    </p>
                    <div
                      className="table-popup"
                      onClick={() => changeActive(null)}
                    >
                      <p>Edit</p>
                      <p>Delete</p>
                    </div>
                  </div>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
}
