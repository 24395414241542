import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import villa1Image from "../assets/images/the-villa/the-villa1.webp";
import villa2Image from "../assets/images/the-villa/the-villa2.webp";
import villa3Image from "../assets/images/the-villa/the-villa3.webp";
import villa4Image from "../assets/images/the-villa/the-villa4.webp";
import villa5Image from "../assets/images/the-villa/the-villa5.webp";
import villa6Image from "../assets/images/the-villa/the-villa6.webp";
import TheVillaModalCarousel from "./TheVillaModalCarousel";

export default function TheVillaCarousel2({ openModal, closeModal }) {
  const carouselData = [
    { id: 1, image: villa1Image, name: "DSC04147-HDR" },
    { id: 2, image: villa2Image, name: "Master Room | High Ground Villa" },
    { id: 3, image: villa3Image, name: "Balcony | High Ground Villa" },
    { id: 4, image: villa4Image, name: "Master Room | High Ground Villa" },
    { id: 5, image: villa5Image, name: "Kitchen | High Ground Villa" },
    { id: 6, image: villa6Image, name: "DSC04147-HDR" },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
    },
  };

  return (
    <Carousel
      className="villa-carousel2"
      responsive={responsive}
      keyBoardControl={true}
      draggable={true}
      swipeable={true}
    >
      {carouselData.map((data, index) => (
        <img
          key={data.id}
          src={data.image}
          alt="The Villa"
          onClick={() =>
            openModal(
              <TheVillaModalCarousel
                openModal={openModal}
                closeModal={closeModal}
                data={carouselData}
                pageNumber={index}
              />
            )
          }
        />
      ))}
    </Carousel>
  );
}
