import React from "react";

export default function TableHeader({
  title,
  deleteLabel,
  deleteClick = () => {
    console.log("default delete handler");
  },
}) {
  return (
    <div className="table-header">
      <h1>{title}</h1>
      <div className="table-header-actions">
        <div className="table-header-icon active">
          <p>Filter</p>
        </div>
        <div className="table-header-icon" onClick={deleteClick}>
          <p>{deleteLabel}</p>
        </div>
      </div>
    </div>
  );
}
