import { createContext, useState, useEffect } from "react";
import { loadUsers, loadBookings, loadChats } from "./adminFunctions";

const UserContext = createContext();

const getUserFromSessionStorage = () => {
  return sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : {};
};

export function UserContextProvider({ children }) {
  const [user, setuser] = useState(getUserFromSessionStorage());
  const [bookings, setbookings] = useState([]);
  const [users, setusers] = useState([]);
  const [chats, setChats] = useState([]);

  const changeuser = (newUser) => {
    sessionStorage.setItem("user", JSON.stringify(newUser));
    setuser(newUser);
  };

  useEffect(() => {
    async function loadData() {
      setusers(await loadUsers(user.token));
      setbookings(await loadBookings(user.token));
      setChats(await loadChats(user.token));
    }
    if (user.info?.isAdmin) loadData();
  }, [user]);

  return (
    <UserContext.Provider value={{ user, changeuser, users, bookings, chats }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
