import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.webp";
import "../../styles/register.css";
import { useContext, useState } from "react";
import LoadingSpinner from "../../components/shared/loading-spinner";
import ENDPOINT from "../../helpers/url";
import toast from "react-hot-toast";
import UserContext from "../../data/UserContext";

export default function Login() {
  const { changeuser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [values, setvalues] = useState({});
  const history = useHistory();
  const location = useLocation();

  const handleInputChange = (e) => {
    setvalues({ ...values, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };
      const response = await fetch(
        `${ENDPOINT}/api/v1/user/login`,
        requestOptions
      );
      const data = await response.json();

      if (data) {
        let { success, message, token, user } = data;
        if (success) {
          toast.success("You are successifully logged in");
          changeuser({ token, info: user });

          const { search } = location;
          const nextPage = new URLSearchParams(search).get("next");
          if (nextPage) history.push(nextPage);
          else history.push("/the-rooms");
        } else {
          toast.error(message || "Incorrect email or password");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Login Failed. Please try again later");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="register-page">
      <div className="register-form-logo">
        <Link to="/">
          <div className="register-logo-container">
            <img src={logo} alt="High Ground Villa logo" />
          </div>
        </Link>
      </div>
      <div className="register-form-container">
        <form onSubmit={handleLogin}>
          <h1>Welcome Back</h1>

          <div className="input-container">
            <input
              required
              type="email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              placeholder="Email"
            />
          </div>
          <div className="input-container">
            <input
              required
              type="password"
              name="password"
              value={values.password}
              onChange={handleInputChange}
              placeholder="Password"
            />
          </div>
          <div className="input-container">
            <button type="submit">
              {loading ? <LoadingSpinner /> : "Login"}
            </button>
          </div>
          <div className="link">
            <p>Don't have an account?</p>
            <Link to="/register">Create Account</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
