import React from "react";
import ENDPOINT from "../helpers/url";
import LoadingSpinner from "./shared/loading-spinner";
import toast from "react-hot-toast";

export default function ContactForm() {
  const [values, setvalues] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (e) => {
    setvalues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(
        `${ENDPOINT}/api/V1/contact`,
        requestOptions
      );
      const data = await response.json();
      if (data) {
        setvalues({ name: "", email: "", subject: "", message: "" });
        toast.success("Thank you for reaching us. Your message was sent.");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-form-container">
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          className="input"
          placeholder="Nom *"
          required
          name="name"
          value={values.name}
          onChange={handleInputChange}
        />
        <input
          className="input"
          placeholder="Email *"
          required
          name="email"
          value={values.email}
          onChange={handleInputChange}
        />
        <input
          className="input"
          placeholder="Sujet"
          name="subject"
          value={values.subject}
          onChange={handleInputChange}
        />
        <textarea
          className="input"
          placeholder="Message"
          name="message"
          value={values.message}
          onChange={handleInputChange}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button disabled={loading} type="submit" className="submit">
            {loading ? <LoadingSpinner /> : "Envoyer"}
          </button>
        </div>
      </form>
      <div className="map-area">
        <iframe
          title="Highground villa map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.3944802789742!2d30.063838314854454!3d-1.9972619985439581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dcaf5356489d53%3A0x90ac9eff3e502ef0!2sHigh%20Ground%20Villa!5e0!3m2!1sen!2srw!4v1630935007237!5m2!1sen!2srw"
          allowfullscreen=""
          className="map"
          loading="lazy"
        />
      </div>
    </div>
  );
}
