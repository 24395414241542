import toast from "react-hot-toast";
import ENDPOINT from "../helpers/url";

export async function loadRooms() {
  const requestOptions = {
    method: "GET",
  };
  try {
    const response = await fetch(`${ENDPOINT}/api/V1/room`, requestOptions);
    const data = await response.json();
    return data || [];
  } catch (error) {
    toast.error("Failed to load rooms");
    return [];
  }
}
