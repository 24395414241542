import React, { useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import UserContext from "../../data/UserContext";
import LoadingSpinner from "../shared/loading-spinner";
import FilterInput from "./FilterInput";
import RoomsDateRangeFilters from "./RoomsDateRangeFilters";
import ENDPOINT from "../../helpers/url";

export default function RoomDetailsFilters({ roomId, price }) {
  const [values, setvalues] = React.useState({ adults: 0, kids: 0 });
  const [booking, setBooking] = React.useState(false);

  const history = useHistory();
  const location = useLocation();

  const { user } = useContext(UserContext);

  const handleInputChange = (name, value) => {
    setvalues({ ...values, [name]: value });
  };

  const handleBookClick = async (e) => {
    if (!user.token) {
      localStorage.setItem("bookingInfo", JSON.stringify(values));
      history.push(`/register?next=${location.pathname}?autoSubmit=true`);
    } else sendBookRequest(values);
  };

  const sendBookRequest = async (body) => {
    setBooking(true);
    try {
      const requestData = {
        room: roomId,
        kids: body.kids,
        adults: body.adults,
        start_date: body.startDate,
        end_date: body.endDate,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.token,
        },
        body: JSON.stringify(requestData),
      };
      const response = await fetch(
        `${ENDPOINT}/api/v1/booking`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        toast.success(
          "Your booking was recorded. Thank you for being a valuable customer."
        );
        setTimeout(() => {
          history.push("/the-rooms");
        }, 1000);
      } else toast.error(data.message);
    } catch (error) {
      toast.error(
        "Error occurred when booking your room.Please try again later."
      );
    } finally {
      setBooking(false);
    }
  };

  useEffect(() => {
    if (location.search.includes("autoSubmit=true")) {
      const bookingInfo = JSON.parse(localStorage.getItem("bookingInfo"));
      if (bookingInfo) {
        setvalues(bookingInfo);
        sendBookRequest(bookingInfo);
      }
      console.log(bookingInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <>
      <div className="room-details-filters">
        <div className="info">
          <p>From</p>
          <h3>{price}</h3>
          <p>Per Night</p>
        </div>
        <div className="inputs">
          <div className="date-pickers">
            <RoomsDateRangeFilters
              labelInline={true}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="filters-inputs">
            <FilterInput
              label="Adults"
              name="adults"
              value={values.adults}
              inputHandler={handleInputChange}
              labelInline={true}
            />
            <FilterInput
              label="Kids"
              name="kids"
              value={values.kids}
              inputHandler={handleInputChange}
              labelInline={true}
            />
          </div>
        </div>
        <button disabled={booking} onClick={handleBookClick}>
          {booking ? <LoadingSpinner /> : "Book Now"}
        </button>
      </div>
      {/* <Popup open={false} handleClose={() => setBookModalOpen(false)}>
        <h3 className="font-normal" style={{ color: "rgb(73, 73, 73)" }}>
          By clicking book now button, you agree that we will contact you and
          will handle payment on the site.
        </h3>
        <div style={{ paddingTop: 30 }}>
          <button className="font-normal place-book-btn">Book now</button>
        </div>
      </Popup> */}
    </>
  );
}
