import React from "react";
import RoomsDateRangeFilters from "./RoomsDateRangeFilters";
import FilterInput from "./FilterInput";
import "../../styles/the-rooms.css";
import { useHistory } from "react-router-dom";

export default function RoomsFilters({
  isSearch,
  defaultStartDate,
  defaultEndDate,
  defaultAdults,
  defaultKids,
}) {
  const [values, setvalues] = React.useState({
    adults: defaultAdults || 1,
    kids: defaultKids || 0,
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });
  const history = useHistory();

  const handleInputChange = (name, value) => {
    setvalues({ ...values, [name]: value });
  };

  const searchRooms = () => {
    console.log("search", values);
    history.push(
      `/the-rooms?adults=${values.adults}&kids=${values.kids}&startDate=${values.startDate}&endDate=${values.endDate}`
    );
  };

  return (
    <div className="rooms-filters">
      <div className="date-pickers">
        <RoomsDateRangeFilters
          labelInline={true}
          handleInputChange={handleInputChange}
          defaultValues={values}
        />
      </div>
      <div className="filters-inputs">
        <FilterInput
          label="Adults"
          name="adults"
          value={values.adults}
          inputHandler={handleInputChange}
          labelInline={true}
        />
        <FilterInput
          label="Kids"
          name="kids"
          value={values.kids}
          inputHandler={handleInputChange}
          labelInline={true}
        />
      </div>
      <button className="filter-submit" onClick={searchRooms}>
        {isSearch ? "Search again" : "Search"}
      </button>
    </div>
  );
}
