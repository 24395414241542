import React from "react";
import { useHistory } from "react-router-dom";
import "../styles/homeFilters.css";
import DateRangeFilters from "./Filters/DateRangeFilters";
import FilterInput from "./Filters/FilterInput";

export default function HomeFilters() {
  const [values, setvalues] = React.useState({
    adults: 1,
    kids: 0,
    startDate: null,
    endDate: null,
  });

  const history = useHistory();

  const handleInputChange = (name, value) => {
    setvalues({ ...values, [name]: value });
  };

  const searchRooms = () => {
    console.log("search", values);
    history.push(
      `/the-rooms?adults=${values.adults}&kids=${values.kids}&startDate=${values.startDate}&endDate=${values.endDate}`
    );
  };

  return (
    <div className="home-filters">
      <div className="date-pickers">
        <DateRangeFilters handleInputChange={handleInputChange} />
      </div>
      <div className="filters-inputs">
        <FilterInput
          label="Adultes"
          name="adults"
          value={values.adults}
          inputHandler={handleInputChange}
        />
        <FilterInput
          label="Enfants"
          name="kids"
          value={values.kids}
          inputHandler={handleInputChange}
        />
      </div>
      <button className="filter-submit" onClick={searchRooms}>
        Rechercher
      </button>
    </div>
  );
}
