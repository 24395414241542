import React from "react";
import Footer from "../../components/Footer";
import HomeFilters from "../../components/HomeFilters";
import LogoContainer from "../../components/LogoContainer";
import Navbar from "../../components/Navbar";

export default function Home() {
  return (
    <>
      <LogoContainer />
      <Navbar />
      <div className="banner">
        <p>MI CASA</p>
        <p>ES SU CASA.</p>
      </div>
      <HomeFilters />
      <div style={{ height: "80px" }}></div>

      <Footer />
    </>
  );
}
