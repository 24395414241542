import React, { useContext } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import "./App.css";
import "./styles/fonts.css";

import Home from "./pages/website/home";
import Contact from "./pages/website/contact";
import Villa from "./pages/website/villa";
import Rooms from "./pages/website/rooms";
import RoomDetailsPage from "./pages/website/room-details";

import Register from "./pages/auth/register";
import LoginPage from "./pages/auth/login";

import Notfound from "./pages/404";

import Users from "./pages/admin/users";
import Mailbox from "./pages/admin/mailbox";
import Bookings from "./pages/admin/Bookings";
import Traffic from "./pages/admin/traffic";
import { Toaster } from "react-hot-toast";
import UserContext from "./data/UserContext";

const NormalRoute = (props) => {
  const { user } = useContext(UserContext);
  const { component, children, ...rest } = props;
  return user.info?.isAdmin ? (
    <Redirect to="/admin"></Redirect>
  ) : (
    <Route {...rest} component={component}>
      {children}
    </Route>
  );
};

function App() {
  const { user } = useContext(UserContext);
  const [isModalOpen, setisModalOpen] = React.useState({
    status: false,
    component: null,
  });

  const location = useLocation();
  const closeModal = () => {
    setisModalOpen({ status: false, component: null });
  };
  const openModal = (component) => {
    setisModalOpen({ status: true, component: component });
  };

  return (
    <div
      className={`${
        location.pathname?.startsWith("/the-rooms") ? "App App-rooms" : "App"
      }`}
    >
      <div>
        <Toaster />
      </div>
      <Switch>
        <NormalRoute exact path="/" component={Home} />
        <NormalRoute exact path="/contact" component={Contact} />
        <NormalRoute
          exact
          path="/the-villa"
          component={() => (
            <Villa openModal={openModal} closeModal={closeModal} />
          )}
        />
        <NormalRoute exact path="/the-rooms" component={Rooms} />
        <NormalRoute exact path="/the-rooms/:id" component={RoomDetailsPage} />
        <NormalRoute exact path="/register" component={Register} />
        <NormalRoute exact path="/login" component={LoginPage} />

        {user.info?.isAdmin && (
          <>
            <Route exact path="/admin" component={Traffic} />
            <Route exact path="/admin/users" component={Users} />
            <Route exact path="/admin/bookings" component={Bookings} />
            <Route exact path="/admin/mailbox" component={Mailbox} />
          </>
        )}
        <Route exact path="*" component={Notfound} />
      </Switch>

      {isModalOpen.status && isModalOpen.component && (
        <div className="page-modal">{isModalOpen.component}</div>
      )}
    </div>
  );
}

export default App;
