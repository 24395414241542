import React from "react";
import Carousel from "react-multi-carousel";

export default function TheVillaModalCarousel({
  openModal,
  closeModal,
  data = [],
  pageNumber,
}) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  let carousel;
  React.useEffect(() => {
    if (carousel && pageNumber) {
      carousel.goToSlide(pageNumber);
    }
  }, [carousel, pageNumber]);

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <Carousel
        className="villa-carousel-modal"
        responsive={responsive}
        keyBoardControl={true}
        draggable={true}
        swipeable={true}
        ref={(el) => (carousel = el)}
      >
        {data.map((d) => (
          <div key={d.id}>
            <img src={d.image} alt="The Villa"></img>
            <p className="carousel-descriptions">{d.name}</p>
          </div>
        ))}
      </Carousel>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="28"
        height="28"
        fill="#fff"
        onClick={closeModal}
        className="carousel-modal-close"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
      </svg>
    </div>
  );
}
